<script setup>
import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";

import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});
</script>

<template>
  <div>
    <HeaderSection />

    <main id="main">
      <section id="about-us" class="section">
        <div
          class="side-triangle"
          data-aos="zoom-in"
          data-aos-delay="200"
        ></div>
        <header
          class="header-banner common claims d-flex justify-content-start"
        >
          <h2 class="highlight">Claims</h2>
        </header>
        <div class="container pb-5" data-aos="zoom-in">
          <!-- <h4 class="highlight pb-4">
            At Pen Underwriting, our goal is to make your claim one less thing
            for you to worry about.
          </h4> -->
          <p>
            At Pen Underwriting, our aim is to make your claim one less thing
            for you to stress about. We recognise that every claim revolves
            around unique circumstances and that's why we will support you
            throughout the entire process, tailoring our approach to your
            specific needs.
          </p>
          <p>
            We prioritise understanding your situation thoroughly and asking the
            right questions to manage your claim effectively, especially during
            what can be a challenging time.
          </p>

          <div class="card mt-5">
            <div class="card-body">
              <div class="row">
                <div class="col-md-8">
                  <p>
                    Please select the product below to download the relevant
                    claims form. Once you have completed the form please submit
                    to
                  </p>
                  <p>
                    <a
                      href="mailto:claims.au@penunderwriting.com"
                      class="btn-round my-3 purple-button"
                      >claims.au@penunderwriting.com</a
                    >
                  </p>
                  <p>And for all Care Liability Claims</p>
                  <p>
                    <a
                      href="mailto:careclaims@penunderwriting.com"
                      class="btn-round my-3 purple-button"
                      >careclaims@penunderwriting.com</a
                    >
                  </p>
                </div>
                <div class="col-md-4">
                  <div class="">
                    <p>
                      <em
                        ><strong>On receipt of your claim we will:</strong></em
                      >
                    </p>
                    <ul data-rte-list="default">
                      <li>
                        <p>Acknowledge receipt of your claim</p>
                      </li>
                      <li>
                        <p>
                          Provide you with details of the Third Party
                          Administrator who will be managing your claim,
                          together with appropriate contact details
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col mt-3">
                  <p>
                    Please allow <strong>One Business Day</strong> from
                    lodgement to contact the appropriate Third Party
                    Administrator.
                  </p>
                  <p>
                    Our Third Party Claims Administrators can be contacted on
                    the phone numbers listed below:
                  </p>

                  <ul>
                    <li>Transport - Insurx <strong>02 8233 3181</strong></li>
                    <li>
                      Construction, Property (excl Hospitality), Liability -
                      Proclaim <strong>1300 552 446</strong>
                    </li>
                    <li>
                      Hospitality Property - Gallagher Bassett
                      <strong>07 3005 1610</strong>
                    </li>
                    <li>
                      Financial Lines Primary - DWF
                      <strong>1300 552 446</strong>
                    </li>
                    <li>
                      Care liability claims:
                      <ul>
                        <li>
                          Aged Care Combined Liability - Berkley Re -
                          <strong>02 8117 2129</strong>
                        </li>
                        <li>
                          Home & Community Care Liability - Proclaim
                          <strong>1300 552 446</strong>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <section>
            <div class="accordion py-4" id="accordionClaims">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Construction
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body pdf-list">
                    <p>
                      <a
                        target="_blank"
                        href="./forms/claims/Construction+Contract+Works+and+Material+Damage+Claim+Form_Fillable+Form.pdf"
                        >Construction - Contract Works and Material Damage Claim
                        Form</a
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Liability
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body pdf-list">
                    <p>
                      <!-- <a
                        target="_blank" href="./forms/claims/Public+and+Products+Liability+Incident+Report+Form+vLBRE0121_Fillable+Form.pdf"
                        >Public and Products Liability Incident Report Form
                        vLBRE0121</a
                      ><br data-preserve-html-node="true" /> -->
                      <a
                        target="_blank"
                        href="./forms/claims/Public+and+Products+Liability+Claim+Form+vLBRE0121_Fillable+Form.pdf"
                        >Public and Products Liability Claim Form</a
                      ><br data-preserve-html-node="true" />

                      <a
                        target="_blank"
                        href="./forms/claims/Hospitality+Liability+Claim+Form+vL0621_Fillable+Form.pdf"
                        >Hospitality Liability Claim Form</a
                      ><br data-preserve-html-node="true" />
                      <!-- <a
                        target="_blank" href="./forms/claims/Hospitality+Liability+Incident+Report+vL0621_Fillable+Form.pdf"
                        >Hospitality Liability Incident Report vL0621</a
                      ><br data-preserve-html-node="true" /> -->
                      <a
                        target="_blank"
                        href="./forms/claims/Care+Providers+Public+and+Products+Liability+Claim+Form+vBREL0121_Fillable+Form.pdf"
                        >Care Providers Public and Products Liability Claim Form </a
                      ><br data-preserve-html-node="true" />
                      <a
                        target="_blank"
                        href="./forms/claims/Care+Providers+Professional+Indemnity+and+Malpractice+Claim+Form+vBREL0121_Fillable+Form.pdf"
                        >Care Providers Professional Indemnity and Malpractice
                        Claim Form</a
                      ><br data-preserve-html-node="true" />
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Financial Lines
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body pdf-list">
                    <p>
                      <a
                        target="_blank"
                        href="./forms/claims/Professional+Risks+Claim+Form+vBRE0121_Fillable+Form.pdf"
                        >Professional Risks Claim Form</a
                      >
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Property
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body pdf-list">
                    <!-- <p>
                      <a
                        target="_blank" href="./forms/claims/Care+Providers+Property+Claim+Form+vXL0121_Fillable+Form.pdf"
                        >Care Providers Property Claim Form vXL0121</a
                      ><br data-preserve-html-node="true" />
                      <br data-preserve-html-node="true" />
                    </p> -->
                    <p>
                      <a
                        target="_blank"
                        href="./forms/claims/Property+Damage+Claim+Form+vSLHB0121_Fillable+Form.pdf"
                        >Property Damage Claim Form</a
                      ><br data-preserve-html-node="true" />
                      <a
                        target="_blank"
                        href="./forms/claims/Machinery+Breakdown+-+Deterioration+of+Refrigerated+Goods+Claim+Form+vSLHB0121_Fillable+Form.pdf"
                        >Machinery Breakdown - Deterioration of Refrigerated
                        Goods Claim Form</a
                      ><br data-preserve-html-node="true" />
                      <!-- <a
                        target="_blank" href="./forms/claims/Electrical+Damage+Repairers+Report.pdf"
                        >Electrical Damage - Repairer's Report vSLHB0121</a
                      ><br data-preserve-html-node="true" /> -->
                      <a
                        target="_blank"
                        href="./forms/claims/Hospitality+Property+Claim+Form+vXL0121_Fillable+Form.pdf"
                        >Hospitality Property Claim Form</a
                      >
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Transport
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body pdf-list">
                    <p>
                      <a
                        target="_blank"
                        href="./forms/claims/HDI+Global+Specialty+SE+Motor+Vehicle+Accident+Claim+Form+vHDI0121.pdf"
                        >HDI Global Specialty SE Motor Vehicle Accident Claim
                        Form</a
                      ><br data-preserve-html-node="true" />
                      <br data-preserve-html-node="true" />
                      Please complete the HDI Global Specialty Claim form and
                      submit to:
                      <br />
                      <a href="mailto:HGS_PenMotorClaims@hdi-specialty.com">
                        HGS_PenMotorClaims@hdi-specialty.com</a
                      ><br data-preserve-html-node="true" />
                      <!-- <a
                        target="_blank" href="./forms/claims/Lloyds+Underwriters+Motor+Vehicle+Accident+Claim+Form.pdf"
                        >Lloyd's Underwriters - Motor Vehicle Accident Claim
                        Form vL0121</a
                      > -->
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- <div class="pt-4">
            <h2 class="py-4">Get In Touch</h2>
            <div class="row claims-get-in-touch">
              <div class="col-12 col-md-4">
                <p><strong>Transport</strong></p>
                <p>Insurx – <a href="tel:0282333181">02 8233 3181</a><br /></p>
              </div>
              <div class="col-12 col-md-4">
                <p><strong>General Liability</strong></p>
                <p>
                  Proclaim – <a href="tel:1300552446">1300 552 446</a><br />
                </p>
              </div>

              <div class="col-12 col-md-4">
                <p><strong>Construction</strong></p>
                <p>
                  Proclaim – <a href="tel:1300552446">1300 552 446</a><br />
                </p>
              </div>

              <div class="col-12 col-md-4">
                <p><strong>Property</strong></p>
                <p>
                  Proclaim – <a href="tel:1300552446">1300 552 446</a><br />
                </p>
              </div>

              <div class="col-12 col-md-4">
                <p><strong>Care Combined Liability</strong></p>
                <p>
                  For any initial enquiry please phone:<br />
                  Kerrie Coxell <a href="tel:(02)93235011">(02) 9323 5011</a>
                  <br />
                </p>
              </div>
            </div>
          </div> -->
        </div>
      </section>
    </main>

    <FooterSection />
  </div>
</template>
